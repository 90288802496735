import { FooterLinkType } from '@dinowallet/uikitv2'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('Navigation'),
    items: [
      {
        label: t('Weboo token'),
        href: 'https://token.webooswap.com/token',
      },
      {
        label: t('Documents'),
        href: 'https://token.webooswap.com/documents',
      },
      {
        label: t('Team'),
        href: 'https://token.webooswap.com/team',
      },
      {
        label: t('Privacy Policy'),
        href: 'https://token.webooswap.com/privacy',
      },
      {
        label: t('Disclaimer & Risk'),
        href: 'https://token.webooswap.com/disclaimer',
      },
      {
        label: t('Earn'),
        href: 'https://earn.webooswap.com',
      },
      {
        label: t('Reward'),
        href: 'https://earn.webooswap.com/reward',
      },
      {
        label: '—',
      },
    ],
  },
  {
    label: t('Join'),
    items: [
      {
        label: t('Telegram Chat'),
        href: 'https://t.me/Webooswap',
      },
      {
        label: t('Telegram Announcement'),
        href: 'https://t.me/WeeboswapChannel',
      },
      {
        label: t('Twitter'),
        href: 'https://twitter.com/webooswap',
      },
      {
        label: t('Medium'),
        href: 'https://medium.com/@webooswap'
      },
      {
        label: t('Github'),
        href: 'https://github.com/WebooSwap'
      }
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/WebooSwap',
      },
      {
        label: t('Documentation'),
        href: 'https://webooswap.gitbook.io/webooswap/',
      },
      {
        label: t('Audits'),
        href: 'https://webooswap.gitbook.io/webooswap/',
      },
      {
        label: t('Safu Scanner'),
        href: 'https://tools.staysafu.org/scan?a=0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
      },
    ],
  },
]
