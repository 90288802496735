import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu, useMatchBreakpoints } from '@dinowallet/uikitv2'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { useCakeBusdPrice } from 'hooks/useBUSDPrice'
import BigNumber from 'bignumber.js'
import config from './config/config'
import configv2 from './config/configv2'
import UserMenu from './UserMenu'
import GlobalSettingsSearch from './GlobalSettingsSearch'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useCakeBusdPrice()
  // const cakePriceUsd = usePriceCakeBusd()
  const cakePriceUsdDisplay = cakePriceUsd ? `${cakePriceUsd.toFixed(8)}` : '...'
  const cakeNumber = new BigNumber(cakePriceUsdDisplay)
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  
  // Mobile Responsive Menu Display
  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl
  const isSmallerScreen = isXs || isSm || isMd

  return (
    <>
      {isLargerScreen && (
        <UikitMenu
          userMenu={<UserMenu />}
          globalMenu={<GlobalSettingsSearch />}
          isDark={isDark}
          toggleTheme={toggleTheme}
          currentLang={currentLanguage.code}
          langs={languageList}
          setLang={setLanguage}
          cakePriceUsd={cakeNumber}
          links={config(t)}
          subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
          footerLinks={footerLinks(t)}
          activeItem={activeMenuItem?.href}
          activeSubItem={activeSubMenuItem?.href}
          buyCakeLabel={t('Buy WEEB')}
          {...props}
        />
      )}
      {isSmallerScreen && (
        <UikitMenu
          globalMenu={<GlobalSettingsSearch />}
          userMenu={<UserMenu />}
          isDark={isDark}
          toggleTheme={toggleTheme}
          currentLang={currentLanguage.code}
          langs={languageList}
          setLang={setLanguage}
          cakePriceUsd={cakeNumber}
          links={configv2(t)}
          subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
          footerLinks={footerLinks(t)}
          activeItem={activeMenuItem?.href}
          activeSubItem={activeSubMenuItem?.href}
          buyCakeLabel={t('Buy WEEB')}
          {...props}
        />
      )}
    </>
  )
}

export default Menu
