import { Box, Flex } from '@dinowallet/uikitv2'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;
  ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 10px' : 'padding: 0 10px')};

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 120px' : 'padding: 0 40px')};
  }
`

export const StyledInputCurrencyWrapper = styled(Box)`
  width: 328px;
  @media screen and (max-width: 282px) {
    width: 260px;
  }
  @media (min-width: 283) and (max-width: 328px) {
    width: 328px;
  }
  @media screen and (min-width: 329px) {
    width: 360px;
  }
`
