import React from 'react'
import { Flex, IconButton, CogIcon, useModal, useMatchBreakpoints } from '@dinowallet/uikitv2'
import SearchV2 from 'views/Info/components/InfoSearchV2'
import SearchMobile from 'views/Info/components/InfoSearchMobile'
import SettingsModal from '../GlobalSettings/SettingsModal'

const GlobalSettingsSearch = () => {
  const [onPresentSettingsModal] = useModal(<SettingsModal />)
  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl
  const isSmallerScreen = isXs || isSm || isMd

  return (
    <Flex style={{ alignItems: 'center' }}>
      {isLargerScreen && (
        <SearchV2 />
      )}
      {isSmallerScreen && (
        <SearchMobile />
      )}
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px" id="open-settings-dialog-button">
        <CogIcon height={24} width={24} color="textSubtle" />
      </IconButton>
    </Flex>
  )
}

export default GlobalSettingsSearch
