/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/no-shadow */
import { MenuItemsType, DropdownMenuItemType } from '@dinowallet/uikitv2'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const configv2: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => 
[
  {
    label: t('Token'),
    icon: 'Currency',
    hideSubNav: true,
    href: '/info/tokens',
    showOnMobile: true,
    showItemsOnMobile: false,
  },
  {
    label: t('Pair'),
    icon: 'Pair',
    hideSubNav: true,
    href: '/info/pools',
    showOnMobile: true,
    showItemsOnMobile: false,
  },
  {
    label: t('Trade'),
    icon: 'SyncAlt',
    hideSubNav: true,
    href: '#',
    showItemsOnMobile: true,
    showOnMobile: true,
    items: [
      {
        label: t('Advance'),
        icon: 'SyncAlt',
        href: '/info/pool/0xe2f1bf9d238a7eb9fc8d268688f01f980be34841?outputCurrency=0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
      },
      {
        label: t('Lite'),
        icon: 'SwapVert',
        href: '/swap?outputCurrency=0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
      },
    ],
  },
  {
    label: t('Info'),
    icon: 'Chart',
    hideSubNav: true,
    href: '/info',
    showOnMobile: true,
    showItemsOnMobile: false,
  },
  // {
  //   label: t('Trade'),
  //   icon: 'Swap',
  //   hideSubNav: true,
  //   href: '/info/pool/0x7a2757703ae55deef5fac0c5c5c0f16ca0a2c13c?inputCurrency=0x7b440d73b1537806ac707b29edefc57e76f25fed&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   showOnMobile: true,
  //   showItemsOnMobile: false,
  // },
  {
    label: t('Earn'),
    href: '#',
    icon: 'Earn',
    showItemsOnMobile: true,
    showOnMobile: true,
    items: [
      {
        label: t('Reflection Reward'),
        href: 'https://earn.webooswap.com/reward',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Sakura Farm'),
        href: 'https://earn.webooswap.com',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Matcha Pool'),
        href: 'https://earn.webooswap.com',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Join Referral'),
        href: 'https://app.webooswap.com/referral',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      // {
      //   label: t('Farms'),
      //   href: '/farms',
      // },
      // {
      //   label: t('Pools'),
      //   href: '/pools',
      // },
    ],
  },
]

export default configv2
