import React from 'react'
import { Button, useWalletModal, useMatchBreakpoints } from '@dinowallet/uikitv2'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  // Mobile Responsive Menu Display
  const { isXs, isSm, isMd, isLg, isXl, isXxl } = useMatchBreakpoints()
  const isLargerScreen = isLg || isXl || isXxl
  const isSmallerScreen = isXs || isSm || isMd

  return (
    <>
      {isLargerScreen && (
        <Button onClick={onPresentConnectModal} {...props}>
          {t('Connect Wallet')}
        </Button>
      )}
      {isSmallerScreen && (
        <Button onClick={onPresentConnectModal} {...props}>
        { t('Connect')}
        </Button>
      )}
    </>
  )
}

export default ConnectWalletButton
