import React from 'react'
import { Skeleton, UserMenuItem } from '@dinowallet/uikitv2'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import history from 'routerHistory'
import { nftsBaseUrl } from 'views/Nft/market/constants'

interface ProfileUserMenuItemProps {
  isLoading: boolean
  hasProfile: boolean
}

const ProfileUserMenuItem: React.FC<ProfileUserMenuItemProps> = ({ isLoading }) => {
  const { account } = useWeb3React()
  const { t } = useTranslation()

  const handleClick = () => {
    history.push(`${nftsBaseUrl}/profile/${account.toLowerCase()}/achievements`)
  }

  if (isLoading) {
    return (
      <UserMenuItem>
        <Skeleton height="24px" width="35%" />
      </UserMenuItem>
    )
  }

  // if (!hasProfile) {
  //   return (
  //     <UserMenuItem as="button" onClick={handleNoProfileClick}>
  //       <Flex alignItems="center" justifyContent="space-between" width="100%">
  //         {t('Make a Profile')}
  //         <Dot />
  //       </Flex>
  //     </UserMenuItem>
  //   )
  // }

  return (
    <UserMenuItem as="button" onClick={handleClick}>
      {t('Your Profile')}
    </UserMenuItem>
  )
}

export default ProfileUserMenuItem
